.material-index {

    .items {

        h2 {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}