.thank-you {
    color: $front-end-text-color;
    font-family: $front-end-text-font;
    margin-top: -50px;
    margin-bottom: -50px;
    background-color: #fff;
    padding-bottom: 50px;
    min-height: 100vh;

    > .container {
        padding: 0 20px;
    }

    h1 {
        margin-top: 120px;
    }
}