#bookingForm {
    .alert-danger {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
         
        ul {
            margin-bottom: 0;
        }
    }

    form {
        margin: auto;

        .client-data {

            .form-check {
                margin-top: 20px;

                label {
                    margin-top: 1px;
                }
            }

            .company-details {
                margin-top: 20px;
            }
        }

        .bikes-toggle {

            .form-check-inline {
                position: relative;
                top: 2px;

                &:first-of-type {
                    margin-left: 8px;
                }
            }
        }

        .form-group {
            align-items: flex-end;
        
            &.ek,
            &.dk,
            &.ck,
            &.zv,
            &.ton,
            &.bikes {
                display: flex;
                flex-wrap: wrap;

                .column {

                    &.quantity {
                        width: calc(65% - 5px);
                        margin-right: 5px;
                    }

                    &.unit-price {
                        width: calc(35% - 5px);
                        margin-left: 5px;
                    }

                    &.unit-price-total {
                        width: calc(35% - 5px);
                        margin-left: auto;
                        margin-top: 10px;
                    }

                    .input-group {
                    flex-wrap: nowrap;
                    align-items: flex-end;

                        input {
                            width: 60px;
                            text-align: right;
                        }
                    }
                }
            }

            &.total {
                text-align: right;
                font-weight: bold;

                label {
                    margin-right: 15px;
                    margin-bottom: 0;
                    font-size: 1.4em;
                    position: relative;
                }

                .col {

                    &:nth-child(2) {
                        padding-left: 0;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) {
    #bookingForm {
        
        form {

            .form-group {

                &.ek,
                &.dk,
                &.ck,
                &.zv,
                &.ton,
                &.bikes {

                    .column {

                        &.quantity {
                            width: calc(50% - 5px);
                        }

                        &.unit-price {
                            width: calc(25% - 10px);
                            margin-right: 5px;
                        }

                        &.unit-price-total {
                            width: calc(25% - 5px);
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}