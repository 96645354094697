.modal {

    .btn-close {
        font-size: 1.5em;

        &:hover {
            cursor: pointer;
        }
    }
}

#editScheduledItemModal {

    .input-group {
        margin-bottom: 10px;

        span {
            width: 70%;
        }

        &.discount {
            flex-wrap: nowrap;

            span {
                width: 100px;

                &:last-child {
                    width: 60px;
                    border-right: 1px solid #ced4da;
                    border-radius: 0.25rem;
                    border-left: none;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }

            #discount {
                text-align: right;
            }
        }
    }

    .discount-reason {
        margin-top: 20px;
    }
}

#cancelScheduledItemModal {

    textarea {
        width: 100%;
        padding: 10px;
    }
}

.custom-modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;

    .modal-wrapper {
        padding: 60px 20px 30px;
        background-color: $white;
        position: relative;
        max-width: 500px;

        @media (min-width: 768px) {
            padding: 60px 40px 30px;
        }

        svg.close-modal {
            width: 30px;
            right: 20px;
            top: 20px;
            position: absolute;
            cursor: pointer;

            @media (min-width: 768px) {
                right: 40px;
            }
        }

        h3 {
            margin-bottom: 30px;
        }

        .btn-ctr {
            text-align: right;
            margin-top: 30px;
        }
    }
}

.modal-error {
    display: none;

    &.active {
        display: flex;
    }
}
