table {
    min-width: 100%;
    border: 1px solid $border-color;

    thead {

        tr {

            th {
                padding: 8px 10px 15px;

                &.table-head-actions {
                    display: flex;
                    gap: 10px;
                    justify-content: flex-end;

                    a {
                        border: 1px solid $blue;
                        border-radius: 50%;
                        display: flex;

                        &.table-head-action-button {

                            svg {
                                width: 22px;
                                height: auto;
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }

    tbody {
        padding: 5px;

        tr {

            td {
                padding: 8px 10px;

                &.row-actions {
                    display: flex;
                    gap: 10px;
                    justify-content: flex-end;

                    a {

                        &.table-row-action-button {
                            width: 18px;
                        }
                    }

                    button,
                    a {
                        padding: 0;

                        &.table-row-action-button {
                            width: 20px;

                            svg {
                                width: 100%;
                                height: 17px;
                                display: inline-block;
                                position: relative;
                            }

                            &.btn-delete {

                                svg {
                                    stroke: $red;
                                    bottom: 0;
                                }
                            }

                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }
                }
            }

            &:nth-of-type(even) {
                background-color: $bg-light;
            }
        }
    }
}
