.input-group {

    input:first-child {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        + .input-group-text {
            border-right: 1px solid #ced4da;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: .25rem;
            border-bottom-right-radius: .25rem;
        }
    }

}