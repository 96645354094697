.front-end-menu {
    padding: 15px 0;

    .container {
        display: flex;
        justify-content: space-between;
        position: relative;

        .logo {
            position: relative;
            left: 15px;

            img {
                width: 40px;
            }
        }

        i {
            font-size: 1.5em;
            color: #2ea3f2;
            position: relative;
            top: 15px;
            right: 20px;

            &.fa-angle-down {
                display: none;
            }
        }

        nav {
            

            ul {
                padding: 0;

                &.main-menu {
                    position: absolute;
                    top: 69px;
                    left: 20px;
                    right: 20px;
                    background-color: #fff;
                    padding: 0 15px;
                    margin: 0;
                    z-index: 1000;
                    overflow: hidden;
                    transition: max-height .3s ease;
                    display: none;

                    >li:first-child {
                        padding-top: 20px;

                        &::before {
                            content: '';
                            display: block;
                            border-top: 3px solid #2ea3f2;
                            position: absolute;
                            left: 0;
                            top: 0;
                            right: 0;
                        }
                    }

                    >li:last-child {
                        margin-bottom: 30px;
                    }
                }

                li {
                    list-style-type: none;
                    border-bottom: 1px solid #f1f1f1;
                    padding: 0 15px;

                    a {
                        color: $front-end-text-color;
                        text-transform: uppercase;
                        padding: 10px 0;
                        display: block;
                    }

                    .sub-menu {
                        li {
                            border-top: 1px solid #f1f1f1;
                            
                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1200px) {

    .front-end-menu {
        padding: 0;

        .container {

            .logo {

                img {
                    width: 65px;
                    margin-top: 10px;
                }
            }

            nav {

                i {
                    display: none;

                    &.fa-angle-down {
                        display: inline;
                        top: 2px;
                        left: 3px;
                        color: $front-end-text-color;
                    }
                }

                ul {

                    &.main-menu {
                        display: flex;
                        position: static;
                        padding-right: 0;
                        overflow: visible;

                        >li:first-child {
                        padding-top: 0px;

                            &::before {
                                content: none;
                            }
                        }

                        >li:last-child {
                            margin-bottom: 0px;
                        }

                        li {
                            list-style-type: none;
                            border-bottom: none;
                            padding: 0 15px;

                            a {
                                display: inline-block;
                                padding: 45px 0;
                            }

                            &.has-sub-menu {
                                position: relative;

                                &:hover {

                                    .sub-menu {
                                        max-height: unset;
                                        border-width: 3px;
                                    }
                                }
                            }

                            .sub-menu {
                                max-height: 0;
                                transition: all .2s ease;
                                width: 200px;
                                border-top: 0px solid black;
                                background-color: #fff;
                                position: absolute;
                                overflow: hidden;

                                li {
                                    border-top: none;
                                    
                                    &:last-child {
                                        border-bottom: none;
                                    }

                                    a {
                                        padding: 25px 0 25px 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
