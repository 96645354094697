form.form-default {

    .form-group {
        display: grid;
        grid: auto / 1fr;
        gap: 15px;

        @media (min-width: 768px) {
            grid: auto / repeat(2, 1fr);
        }

        &.form-group-full {
            grid-column: 1 / -1;
        }

        .input-group {
            display: grid;
            grid: auto / 1fr;
            gap: 7px;

            label {
                margin-bottom: 0;
            }

            input,
            select {
                display: block;
                width: 100%;
                height: calc(1.5em + 0.75rem + 2px);
                padding: 0.375rem 0.75rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #495057;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
                transition: border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
            }
        }
    }

    .btn-ctr {
        margin-top: 30px;

        .btn-primary {
            width: 100%;
        }
    }
}
