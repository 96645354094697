.general-conditions {
    color: $front-end-text-color;
    font-family: $front-end-text-font;
    margin-top: -50px;
    margin-bottom: -50px;
    background-color: #fff;
    padding-bottom: 50px;

    h1,
    h2 {
        color: $front-end-title-color;
        font-family: $front-end-title-font;
    }

    a {
        color: $brown;
    }

    .btn-primary {
        background-color: $brown;
        border-color: $brown;
    }

    #front-end-menu {
        border-bottom: 1px solid $front-end-text-color;
    }

    .sub-menu {
        box-shadow: 0 0 8px lightgrey;
    }

    #page-title {
        padding: 50px 10px;
    }

    #text {
        padding: 0 10px;

        .container {

            > p {

                &:first-child {
                    font-size: 1.4em;
                }
            }

            ul {
                padding-left: 20px;
            }

            table {
                margin-bottom: 1em;
                text-align: center;
                border: 1px solid $border-color;
                
                tbody {
                    padding: 0 20px 20px;
                    display: block;
                }

                th {
                    border-bottom: 1px solid $border-color;
                    padding: 20px 10px 10px;
                }

                td {
                    padding: 10px;
                    border: 1px solid $border-color;
                    
                }
            }

            h2 {
                font-size: 1.4em;
                margin: 1.5em 0 1em;
            }
        }
    }
}