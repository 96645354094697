.admin,
.reports-dashboard,
.material-index {
    .item {
        height: 200px;
        width: auto;
        margin: auto;
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;

        padding: 20px;
        margin-bottom: 25px;
        transition: all .5s ease;

        i {
            font-size: 2.1em;

            &.fa-tasks,
            &.fa-calendar-alt {
                font-size: 2.2em;
            }

            &.fa-file-invoice,
            &.fa-file-check {
                font-size: 2.2em;
            }

            &.fa-chart-line {
                font-size: 2.5em;
            }
        }

        h2 {
            margin-bottom: 0;
            font-weight: normal;
        }

        &:hover {
            text-decoration: none;
            transform: scale(1.05);
        }
    }
}

.reports-dashboard {

    .item {
        align-items: center;
    }
}
