.stock {
    input[type="checkbox"] {
        width: 15px;
        height: 15px;
        position: relative;
        top: 2px;
    }

    #summary {
        margin-bottom: 15px;

        span {
            font-size: 1.2em;
            font-weight: bolder;
            margin-left: 5px;
        }
    }
    
    #action-btns{
        margin-bottom: 15px;

        .col-12 {
            
            .inner-ctr  {
                border: 1px solid rgba(0, 0, 0, 0.1);
                padding: 20px;
                background-color: #fff;

                > * {
                    margin-bottom: 15px;
                    
                }
    
                .all-ctr {
                
                    #all {
                        margin-right: 5px;
                    }
                }
    
                .buttons-ctr {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    padding-bottom: 20px;
                    
                    button {
                        width: 48%;
    
                    }
                }
    
                form.type-filter {
                    display: flex;
                    justify-content: space-between;
                    
                    select#type {
                        height: 37px;
                        width: 48%;
                        
                    }
    
                    button {
                        width: 48%;
                        height: 39px;
                    }
                }
            }
        }  
    }

    #overview {

        .col-12 {

            &:nth-of-type(even) {
                
                .item {
                    background-color: $bg-light;
                }
            }

            .item {
                padding: 20px;
                margin-bottom: 20px;

                

                .data {
                    display: flex;
                    flex-wrap: wrap;

                    > * {
                        margin-bottom: 20px;
                        padding-top: 5px;
                        padding-bottom: 5px;
                    }
    
                    .check {
                        border-right: 1px solid $border-color;
                        width: calc(25% - 1px);
                    }
    
                    .id {
                        border-right: 1px solid $border-color;
                        padding-left: 5%;
                        width: calc(25% - 1px);
                    }
    
                    .type {
                        width: 50%;
                        padding-left: 5%;
                    }
    
                    .price {
                        border-right: 1px solid $border-color;
                        width: calc(50% - 1px);
                    }
    
                    .status {
                        width: 50%;
                        padding-left: 5%;
                    }

                }

                .buttons {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 0;

                    .btn {
                        width: 48%;
                    }

                    form {
                        width: 48%;

                        .btn {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .stock {

        .pagination {

            ul.pagination {
                flex-wrap: wrap;
            }
        }
    }
}

@media only screen and (min-width: 992px) {
    .stock {

        #action-btns {
            .col-12 {
    
                .inner-ctr {
                    display: flex;
                    flex-wrap: wrap;

                    > * {
                        margin-bottom: 0;
                    }
    
                    .all-ctr {
                        width: 20%;
                    }
    
                    .buttons-ctr {
                        width: 40%;
                        padding-right: 15px;
                        padding-bottom: 0;
                        border-bottom: 0;
                        margin-bottom: 0;
                        padding-right: 15px;
                        border-right: 1px solid $border-color;
                    }
    
                    form.type-filter {
                        width: 40%;
                        padding-left: 15px;
                    }
                }
            }
        }

        #overview {

            .col-12 {

                .item {
                    display: flex;
    
                    .data {
                        width: 70%;
                    }
    
                    .buttons {
                        width: 30%;
                        display: block;
                        border-left: 1px solid $border-color;
                        padding-left: 20px;
    
                        .btn {
                            width: 100%;

                            &:first-of-type {
                                margin-bottom: 15px;
                            }
                        }

                        form {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}