#app-admin {

    #filters {

        .status-btns {

            .btn {
                width: 100%;

                &.btn-outline-warning {
                    margin-bottom: 10px;
                }
            }
        }
    }
}

.invoice-item {
    padding: 20px;
    border: 1px solid $border-color;
    margin-bottom: 20px;

    header {
        font-size: 1.3em;
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 10px;
        text-align: center;

        .checkbox {
            background-color: transparent;
            border: none;
            border-right: 1px solid $border-color;

            &:focus,
            &:focus-visible {
                outline: none;
            }

            i {
                font-size: 1.2em;
                position: relative;
                top: 2px;
                transition: color .2s ease;

                &.orange {
                    color: $warning;
                }

                &.green {
                    color: $success;
                }

                &:hover {
                    color: $success;
                    cursor: pointer;
                }
            }
        }

        .date {
            padding: 7px 10px 6px;
            font-size: .65em;
        }

        .name {

            .arrows {
                margin-top: 20px;

                svg {
                    cursor: pointer;
                    width: 25px;
                    height: 25px;
                    margin-left: 10px;
                    position: relative;
                    top: 0;
                    transition: top .2s ease;

                    &:hover {
                        top: 6px;
                    }
                }

                i {
                    font-size: .8em;
                    margin-left: 10px;
                    position: relative;
                    top: 0px;
                    transition: top .2s ease;

                    &:hover {
                        cursor: pointer;
                        top: 6px;
                    }
                }
            }
        }

    }

    .body {
        display: none;
        border-top: 1px solid $border-color;
        margin-top: 20px;
        padding: 20px 0px 0;
        transition: all .3s ease;

        &.active {
            max-height: 1000px;
            border-top: 1px solid $border-color;
            margin-top: 20px;
            padding: 20px 0px 0;

            .content-wrapper {
                opacity: 1;
            }
        }

        ul {
            padding-left: 20px;
        }

    }
}


@media (min-width: 768px) {
    #app-admin {

        #filters {

            .status-btns {

                .btn {
                    width: auto;

                    &.btn-outline-warning {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }

    .invoice-item {

        header {
            grid-template-columns: 45px 250px auto;

            .checkbox {
                grid-column-start: 1;
                grid-column-end: 2;
                text-align: left;
                border-right: 1px solid $border-color;
            }

            .date {
                margin-left: 20px;
                margin-right: 20px;
            }

            .name {
                border-left: 1px solid $border-color;
                padding-left: 20px;
                text-align: left;
            }

            .arrows {
                margin: 0;
            }
        }

        .body {

            .content-wrapper {
                display: flex;

                .company-details {
                    padding-right: 20px;
                    border-right: 1px solid $border-color;
                    width: 50%;
                }

                .bookings-details {
                    padding-left: 20px;
                    width: 50%;
                }
            }
        }
    }
}
