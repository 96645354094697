#navigation {

    a {
        display: block;

        & + a {
            margin-top: 10px;
        }
    }
}

@media only screen and (min-width: 768px) {
    #navigation {

        a {
            display: inline-block;

            & + a {
                margin-top: 0;
            }
        }
    }
}