#masthead {

    a {

        .fal {
            font-size: 1.7em;
            color: #fff;
            position: relative;
            top: 3px;
            transition: transform .3s ease;
        }

        &:hover {

            .fal {
                transform: scale(1.3);
            }
        }
    }

    .navbar {
        position: static;
    }

    .navbar-toggler {
        border: none;

        i {
            font-size: 1.3em;
            color: #fff;

            &.fa-times {
                position: relative;
                right: 3px;
            }
        }

        &:focus {
            outline: none;
        }
    }

    .navbar-collapse {
        text-align: center;
        position: absolute;
        left: 0;
        top: 53px;
        width: 100%;
        z-index: 1;
        max-height: 0;
        overflow: hidden;
        transition: all .5s ease;
        background-color: $aquarel;

        &.show {
            max-height: 100vh;
            padding: 20px;
        }
    }
}

@media (min-width: 992px) {
    #masthead {

        .navbar {
            height: 75px;
            align-items: center;
        }

        .nav-link {
            padding: 0 20px;
            padding-top: 3px;
        }

        .navbar-collapse {
            padding: 0;
            position: relative;
            top: 0;
            max-height: unset;
            overflow: visible;

            &.hide {
                opacity: 1;
                height: auto;
            }
        }

        ul {

            &.navbar-nav {

                li {
                    display: flex;
                    align-items: center;
                    text-align: center;
                }
            }
        }
    }
}
