.btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    i {
        margin-right: 10px;
    }

    svg {
        width: 20px;
        height: 20px;
        position: relative;
        bottom: 2px;
    }

    &[type=submit] {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        letter-spacing: .1em;

        .spinner {
            width: 16px;
        }
    }
}

.btn-primary {
    background-color: $aquarel;
    border-color: $aquarel;

    &:hover,
    &:focus {
        background-color: transparent;
        color: $aquarel !important;
        border-color: $aquarel;
    }
}

.btn-outline-primary {
    color: $aquarel;
    border-color: $aquarel;

    &:hover,
    &:focus {
        background-color: $aquarel;
        border-color: $aquarel;
    }
}

.btn-outline-warning {
    color: $orange;
    border-color: $orange;
}

.btn-outline-warning:not(:disabled):not(.disabled).active {
    background-color: $orange;
    border-color: $orange;
}

.btn-outline-success:not(:disabled):not(.disabled).active {
    background-color: $green;
    border-color: $green;
}

.btn-outline-success {
    color: $green;
    border-color: $green;

    &:hover {
        background-color: $green;
        border-color: $green;
    }
}

.btn-link {
    color: $aquarel;
}

.btn-warning {
    background-color: $orange;
    border-color: $orange;

    &:hover {
        border-color: $orange;
        color: $orange !important;
        background-color: transparent;
    }
}

.btn-back {
    i {
        margin-right: 8px;
        font-size: .9em;
        position: relative;
        left: 2px;
        transition: left .2s ease;
    }

    &:hover {
        i {
            left: -2px;
        }
    }
}

.add-booking-btn {
    width: 28px;
    margin-right: 30px;
    transition: all .2s ease;

    &:hover {
        transform: scale(1.2);
    }
}

#filters {
    .form-group {
        button {
            width: 100%;
        }
    }
}

@media (min-width: 768px) {
    #filters {
        .form-group {

            button {
                margin-top: 30px;
                max-width: 200px;
            }
        }
    }
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle,
.btn-outline-warning:hover,
.btn-outline-primary:focus {
    color: #fff;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle,
.btn-outline-info:hover {
    color: #fff;
}
