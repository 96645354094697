// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Base
@import 'base/variables';
@import 'base/mixins';
@import 'base/global';

// Components
@import 'components/buttons';
@import 'components/header';
@import 'components/footer';
@import 'components/forms/form-default';
@import 'components/forms/clientBooking';
@import 'components/pagination';
@import 'components/input-group';
@import 'components/modal';
@import 'components/front-end-menu';
@import 'components/navigation';
@import 'components/tables';

// Frontend
@import 'frontend/index';

// Pages
@import 'pages/thankyou';
@import 'pages/admin';
@import 'pages/dashboard';
@import 'pages/general-conditions';

@import 'pages/bookings/index';
@import 'pages/bookings/create';
@import 'pages/bookings/edit';
@import 'pages/bookings/schedule';
@import 'pages/bookings/pickup';

@import 'pages/materials/index';
@import 'pages/materials/create';
@import 'pages/materials/stock';
@import 'pages/materials/availability';

@import 'pages/reports/index';
@import 'pages/reports/daily';

@import 'pages/search';
