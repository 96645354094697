.index-front-end {
    color: $front-end-text-color;
    font-family: $front-end-text-font;
    margin-top: -50px;
    margin-bottom: -50px;
    background-color: #fff;
    padding-bottom: 50px;

    section.front-end-menu {

        a {
            font-family: 'Open Sans', Arial, sans-serif;
            font-size: 15px;
            font-weight: bold;
            color: rgba(0, 0, 0, .6);
            transition: all .3s ease;

            &:hover {
                opacity: .7;
            }
        }
    }

    h1,
    h2 {
        color: $front-end-title-color;
        font-family: $front-end-title-font;
    }

    p,
    ul {
        font-size: 17px;
        color: #939393;
    }

    a {
        color: $brown;
    }

    .btn-primary {
        background-color: $brown;
        border-color: $brown;
    }

    .page-title {
        padding: 180px 0;
        background-image: url('/img/ark-map.png');
        background-position: top;
        background-repeat: no-repeat;

        h1 {
            font-size: 3.5em;
            margin: 0;
            text-align: center;
        }
    }

    #bookingForm {

        h2 {
            margin: 2em 0 20px;
            color: #333;
            line-height: .9em;
        }

        form {
            margin-top: 3em;
            padding: 20px 10px;
            background-color: #f3f3f3;

            label {
                margin-left: 3px;
            }

            h3 {
                margin-bottom: 30px;
            }

            p.error {
                color: red;
            }

            .btn-primary {
                letter-spacing: .1em;
            }

            .btn-admin {
                display: none;
            }

            .btn-outline-primary {
                border-color: $brown;
                color: $brown;

                svg {
                    position: relative;
                    top: -1px;
                    transition: all .3s ease;

                    &.arrow-circle-left {
                        right: 5px;
                    }

                    &.arrow-circle-right {
                        left: 5px;
                    }
                }

                &:hover {
                    background-color: transparent;

                    .arrow-circle-right {
                        left: 15px;
                    }

                    .arrow-circle-left {
                        right: 15px;
                    }
                }

                &:focus {
                    box-shadow: none;
                }
            }

            .step-nav {
                width: 100%;
                text-transform: uppercase;

                &.step-nav-fw {
                    margin-top: 10px;
                }

                &.step-nav-back {
                    margin-bottom: 30px;
                }

                i {
                    font-size: 1.4em;
                    position: relative;
                    top: 3px;
                    transition: all .3s ease;

                    &.fa-arrow-circle-right {
                        left: 8px;
                    }

                    &.fa-arrow-circle-left {
                        right: 8px;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .index-front-end {
        .logo {

            img {
                width: 60px;
            }
        }

        .page-title {


            h1 {
                font-size: 4.5em;
            }
        }

        #bookingForm {

            h2 {
                font-size: 2.5em;
                margin-bottom: 20px;
                height: 150px;
            }
        }
    }
}

@media (min-width: 1200px) {
    .index-front-end {

        .page-title {
            padding: 240px 0;
            background-size: cover;
            background-position: 50% 30%;
        }
    }
}
