.bookings-create {

    #navigation {
        margin-top: 50px;
    }

    #bookingForm {
        margin: 30px 0 30px;
    }

    .alert {
        margin-bottom: 50px;
    }
    
    form {
        margin: auto;

        #booking-info {

            .alert {
                display: none;
            }

            .btn-front,
            .acceptance {
                display: none;
            }

            h3 {
                margin-bottom: 30px;
            }

            .btn-outline-primary {

                &:hover {

                    .fa-arrow-circle-right {
                        left: 15px;
                    }

                    .fa-arrow-circle-left {
                        right: 15px;
                    }
                }

                &:focus {
                    box-shadow: none;
                }
            }

            .material {

                .discount {
                    display: flex;

                    label {
                        width: 50%;
                    }

                    .input-group {
                        width: 50%;
                        padding-left: 15px;
                    }
                }
            }

            .invoice {
                margin-top: 30px;

                input[type="checkbox"] {
                    position: relative;
                    top: 1px;
                    margin-right: 5px;
                }
            }

            button[type="submit"] {
                letter-spacing: .1em;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .bookings-create {

        form {

            div#booking-info {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-column-gap: 40px;

                .client-data {
                    grid-column-start: 1;
                    grid-column-end: 3;
                }

                .time-place {
                    grid-column-start: 3;
                    grid-column-end: 5;
                }

                .material {
                    grid-column-start: 1;
                    grid-column-end: 5;
                }

                .invoice {
                    grid-column-start: 1;
                    grid-column-end: 5;
                }

                button[type="submit"] {
                    grid-column-start: 1;
                    grid-column-end: 5;
                }
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .bookings-create {

        form {

            div#booking-info {
                grid-row-gap: 20px;

                .client-data {
                    grid-column-start: 1;
                    grid-column-end: 3;
                }

                .time-place {
                    grid-column-start: 3;
                    grid-column-end: 5;
                }

                .material {
                    grid-column-start: 1;
                    grid-column-end: 3;
                }

                .invoice {
                    grid-column-start: 3;
                    grid-column-end: 5;
                    margin-top: 0;
                }
            }
        }
    }
}