.pagination {
    margin-bottom: 0;

    &.top {
        margin-bottom: 20px;
        
        nav {
            border-bottom: 1px solid $border-color;
            padding-bottom: 20px;
        }
    }

    &.bottom {
        
        nav {
            border-top: 1px solid $border-color;
            padding-top: 20px;
        }
    }
}