.pickup {

    .print {
        visibility: hidden;
    }

    #filter {
        input,
        button {
            width: 100%;
            margin-bottom: 1rem;
        }
    }
}

@media only screen and (min-width: 768px) {
    .pickup {
        #filter {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: 1rem;

            input,
            button {
                margin-bottom: 0;
            }
        }
    }
}

@media print {
    .pickup {

        .print {
            visibility: visible;
        }

        #filter,
        #navigation {
            display: none;
        }
    }
}