.booking-edit {
    
    form {

        .client-data {

            .form-group {

                .form-check {
                    position: relative;
                    top: 2px;

                    &:first-of-type {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}