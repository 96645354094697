.search {

    #search-form {

        .container {

            .row {

                .col-12 {
                    
                    div {
                        padding-bottom: 1rem;
                        border-bottom: 1px solid $border-color;
                        
                        form {
                            max-width: 600px;
                            margin: auto;

                            button {
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }
            
        }
    }

    #search-results {
        padding-top: 30px;

        .container {

            h2 {
                text-align: center;
            }

            .results {
                margin-top: 50px;
                border: 1px solid $border-color;
                padding: 10px;

                .item {
                    padding: 20px 10px 10px;
                    display: block;
                    color: inherit;
                    border-bottom: 1px solid $bg-light;
                    text-align: center;

                    &:last-child {
                        border-bottom: none;
                    }

                    &:hover {
                        background-color: $bg-light;
                    }

                    > * {
                        margin-bottom: 10px;
                    }

                    h3 {
                        font-size: 1.2rem;
                    }
                }

                p {
                    margin: 0;
                    padding: 20px;
                    text-align: center;
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .search {

        #search-form {

            .container {

                .row {

                    .col-12 {

                        div {

                            form {
                            display: grid;
                            grid-template-columns: 1fr auto;
                            grid-column-gap: 10px;

                                button {
                                    margin-top: 0;
                                    min-width: 150px;
                                }
                            }
                        }
                    }
                }
            }
        }

        #search-results {

            .container {

                .results {

                    .item {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                        grid-column-gap: 10px;
                    }
                }
            }
        }
    }
}