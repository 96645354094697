.availability {
    #navigation {
        margin-top: 50px;
    }

    #filter {

        form {
            display: grid;
            grid-template-columns: auto auto;
            grid-column-gap: 10px;

            button {
                width: 100%;
                min-width: 100px;
            }
        }
    }

    #filter-results {
        margin-top: 50px;

        .container {
            
            .item {
                margin-bottom: 10px;
                padding: 10px 20px 0;

                &:nth-of-type(even) {
                    background-color: $bg-light;
                }

                .time {
                    font-size: 1.5em;
                    padding-bottom: 5px;
                    border-bottom: 1px solid $border-color;
                }

                .resources {
                    margin-top: 12px;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-column-gap: 10px;

                    h3 {
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .availability {

        #filter-results {

            .container {

                .item {

                    .resources {
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                    }
                }
            }
        }
    }
}