.bookings {
    #filters {
        .form-group {
            &:last-child {
                margin-bottom: 0;
            }

            input[type="date"] {
                padding-left: 5px;
                padding-right: 5px;
                height: 36px;
                width: 100%;
            }
            select {
                height: 36px;
                padding-left: 5px;
                padding-right: 5px;
                width: 100%;
            }
        }

        .search {

            .search-btn {
                color: $info;
                width: 266px;
                width: 100%;

                i {
                    margin-right: 10px;
                }

                &:hover {
                    color: #fff;
                }
            }
        }

        .status-btns {

            a {
                width: 100%;
                margin-bottom: 1rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        #filter {

            .btn-primary {
                width: 100%;
            }
        }
    }
    #overview{
        .row {
            padding-top: 10px;
            padding-bottom: 10px;
            &:nth-of-type(even) {

                .item,
                .buttons {
                    background-color: $bg-light;
                }
            }
        }
        .item {
            margin-bottom: 10px;
            &:last-of-type {
                margin-bottom: 0;
            }

            header {
                display: flex;
                flex-wrap: wrap;

                .badge-wrapper {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 20px;

                    span {
                        width: 100%;

                        span {
                            padding: 5px 10px;
                        }

                        &+ span {
                            margin-top: 10px;
                        }
                    }
                }

                h2 {
                    margin-bottom: 0;
                    width: 80%;
                    padding-left: 15px;
                }
                .arrows {
                    position: relative;
                    width: 20%;

                    svg {
                        font-size: 1.3em;
                        position: absolute;
                        top: 8px;
                        right: 15px;
                        transition: all .1s ease;

                        &:hover {
                            cursor: pointer;
                        }
                        &.fa-chevron-down {
                            &:hover {
                                top: 12px;
                            }
                        }

                        &.fa-chevron-up {
                            display: none;

                            &:hover {
                                top: 3px;
                            }
                        }
                    }
                }
                .status {
                    width: 100%;
                    margin-top: 20px;
                    color: #fff;
                    text-align: center;
                }
            }
            .item-content {
                display: none;

                .content-1 {

                    .price {

                        span {
                            color: $blue;
                        }
                    }

                    .gla_res_nr {

                        span {
                            color: $blue;
                        }
                    }
                }

                .content-5 {
                    padding-left: 15px;

                    h3 {
                        font-size: 1.3em;
                    }
                }
            }
        }
        .buttons {
            a {
                display: block;
                width: 100%;
                margin: auto;

                &+a {
                    margin-top: 1rem;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .bookings {
        #overview {
            .item-wrapper {
                .col-md-4 {
                    margin-top: 10px;
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .bookings {
        #filters {
            .search {

                .search-btn {
                    width: calc(50% - .5rem);
                }
            }

            #filter {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-column-gap: 1rem;

                .form-group {
                    width: 100%;

                    button {
                        max-width: unset;
                    }
                }
            }

            .status-btns {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 1rem;
                grid-row-gap: 1rem;

                a {
                    margin-bottom: 0;
                }
            }
        }
        #overview {
            .item {
                min-height: 178px;
                header {
                    .status {
                        margin-top: 19px;
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .bookings {
        #overview {
            .col-lg-6 {
                &:nth-of-type(1) {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .bookings {
        #filters {

            .search {

                .search-btn {
                    width: calc(25% - 1rem);
                }
            }

            .status-btns {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
        }

        #overview {

            .item {

                header {

                    .badge-wrapper {

                        span {
                            width: calc( 100% / 3);
                            padding: 0px 10px;

                            &:first-of-type {
                                padding-left: 0;

                                & span {
                                    padding-left: 10px;
                                }
                            }

                            span {
                                width: 100%;
                            }

                            &+span {
                                margin-top: 0;
                                border-left: 1px solid $font-color;
                            }
                        }
                    }
                }
            }
        }
    }
}
