.report-daily {

    #result {

        .filter {
            display: flex;
            flex-wrap: wrap;

            * {
                width: 100%;
            }

            input {
                margin-bottom: 10px;
                padding: 8px 20px;
            }

            button {
                padding: 11px;
            }
        }

        .mops {
            padding: 26px 10px 0;
            background-color: $bg-light;

            .col-6 {
                margin-bottom: 20px;

                h4 {
                    margin-bottom: 20px;
                }
            }
        }

        .result {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid $border-color;
            text-align: center;

            h3 {
                margin-bottom: 20px;
            }

            p {
                font-size: 1.6em;
                padding: 15px;
                background-color: $bg-light;
            }
        }
    }
}

@media (min-width: 768px) {
    .report-daily {

        #result {

            .mops {
                margin-top: 20px;
            }

            .result {
                margin-top: 0px;
                padding: 20px;
                border-top: none;
                border-left: 1px solid $border-color;
            }
        }
    }
}

@media (min-width: 992px) {
    .report-daily {

        #result {

            .result {
                border-right: 1px solid $border-color;
            }
        }
    }
}