body {
    color: $black;
}

section {

    &.errors {
        padding: 30px 0 0;

        p {
            text-align: center;
            font-size: 1.3em;
            color: red;
        }
    }
}

h1 {
    margin-top: 40px;
    margin-bottom: 50px;
    color: $black;

    &+.subtitle {
        margin-top: -30px;
        font-size: 1.5em;
    }
}

a {
    color: $aquarel;

    &:hover {
        text-decoration: none;
    }
}

.content {
    padding: 50px 0;
    min-height: calc(100vh - 130px);
}

.text-primary {
    color: $aquarel !important;
}

.border-primary {
    border-color: $aquarel !important;
}

.bg-primary {
    background-color: $aquarel !important;
}

.bg-light {
    background-color: #ededed !important;
}

.bg-dark {
    hr {
        border-color: #fff;
    }
}

.bg-success {
    background-color: $green !important;
}

.bg-warning {
    background-color: $orange !important;
}

.bg-blue {
    background-color: $blue;
}

.input-group {

    .input-group-text {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
    }

    span {

        &:last-of-type {
            border-radius: 0 0.25rem 0.25rem 0;
            border-left: none;
            border-right: 1px solid #ced4da;
        }
    }
}

svg {
    display: inline-block;
    width: 30px;
    height: 30px;
}
