.schedule {
    #filters {
        
        a {
            width: 100%;
            margin-bottom: 1rem;
        }
    }

    #schedule {
        .row {
            padding-top: 10px;
            padding-bottom: 10px;
            &:nth-of-type(even) {
                background-color: $bg-light;
            }
        }
        .summary {
            .hour {
                font-size: 1.5em;
                font-weight: bold;
            }
            .place {
                font-size: 1.2em;
                font-style: italic;
            }
            .material {
                margin-top: 10px;
            }
        }
        .item {
            margin-bottom: 10px;

            &:last-of-type {
                margin-bottom: 0;
            }

            header {
                margin-bottom: 20px;
                position: relative;
                padding-right: 20px;

                .arrows {
                    position: absolute;
                    right: 0px;
                    top: 10px;

                    &:hover {
                        cursor: pointer;
                    }

                    i {
                        font-size: 1.3em;
                    }

                    .fa-chevron-up {
                        display: none;
                    }
                    
                }

                h2 {
                    margin-bottom: 0;
                }

                .btn {
                    margin-top: 1rem;
                    margin-bottom: 0;
                    width: 100%;
                }

            }
            .item-content {
                display: none;
                padding-top: 15px;
                border-top: 1px solid $border-color;

                .content-3 {

                    ul {
                        padding-left: 0;

                        li {
                            display: flex;
                            margin-bottom: 10px;


                            .number {
                                padding-right: 10px;
                                width: calc(100% - 140px);
                            }

                            .unitPrice {
                                width: 80px;
                                padding: 0 10px;
                                border-left: 1px solid $border-color;
                                text-align: center;
                            }

                            .totalUnitPrice {
                                width: 80px;
                                padding-left: 10px;
                                border-left: 1px solid $border-color;
                                text-align: right;
                                font-weight: bold;
                            }

                            &.discount {
                                text-align: right;
                                display: block;
                                margin-top: 20px;
                                color: $red;
                            }

                            &.totalPrice {
                                font-size: 1.2em;
                                font-weight: bold;
                                justify-content: flex-end;
                                margin-top: 1px;
                                color: $green;

                                span {
                                    margin-left: 5px;
                                }
                            }

                        }
                    }
                }
            }
        }
    }

    #overview {

        .daily-schedule {
            margin-bottom: 10px;

            .dashboard {

                form {
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-row-gap: 10px;
                }
            }
        }

        .weekly-schedule {

            .dashboard {
                form {
                    
                    * {
                        width: 100%;
                        margin-bottom: 1rem;
                    }
                }

                > ul {
                    padding-left: 0px;

                    > li {
                        display: flex;
                        flex-wrap: wrap;
                        padding-bottom: 5px;
                        margin-bottom: 10px;
                        border-bottom: 1px solid $border-color;

                        &:last-child {
                            border-bottom: none;
                            padding-bottom: 0;
                            margin-bottom: 0;
                        }

                        div.date {
                            text-align: center;
                            width: 60px;
                            margin-right: 20px;

                            span {
                                display: inline-block;
                                width: 60px;
                                padding: 2px 10px;
                                background-color: $blue;
                                color: $white;
                                border-radius: 5px;
                            }

                            small {
                                display: block;
                            }
                        }

                        div.reservations {
                            width: calc(100% - 80px);
                            text-align: center;
                        }

                        ul.materials {
                            width: 100%;
                            margin-top: 10px;
                            padding-left: 0;
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                            text-align: center;

                            li {
                                list-style-type: none;
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}

#print {
    display: none;
}

@media (min-width: 768px) {
    .schedule {

        #filters {
            
            .col-12 {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 1rem;
            }  
        }

        #overview {

            .daily-schedule {
                margin-bottom: 0;

                .dashboard {
                    height: 100%;
                }
            }

            .weekly-schedule {

                .dashboard {

                    > ul {
                        
                        > li {
                            display: grid;
                            grid-template-columns: auto auto 1fr;
                            grid-column-gap: 20px;

                            div.date {
                                margin-right: 0;
                            }

                            div.reservations {
                                width: 120px;
                            }

                            ul.materials {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .schedule {

        #filters {
            
            .col-12 {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
             
        }

        #schedule {

            .item {

                .buttons {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-column-gap: 20px;
                    margin-top: 20px;

                    .btn {
                        margin: 0;
                    }
                    
                }

                .item-content {

                    .content-3 {

                        ul {
                            padding-left: 20px;
                        }
                    }
                }
            }
        }

        #overview {

            .dashboard {
                
                &:first-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media print {
    .content {
        padding: 20px 0;
    }

    h1 {
        margin-top: 0;
    }

    .schedule {
        display: none;
    }

    #print {
        display: block;
    }
}

#print {

    .summary {

        display: flex;

        p {
            margin-right: 20px;
            font-size: 1.5em;
            border-right: 1px solid $border-color;
            padding-right: 20px;
            width: calc(40% - 40px);
        }

        ul {
            width: 30%;
            margin-left: 20px;
        }
    }

    .row {
        display: block;

        .summary {
            background-color: $bg-light; 
            display: flex;
            padding: 10px 20px;
            align-items: center;
            font-size: 1.2em;
            
            .hour {
                width: 8%;
                padding: 5px;
                border-right: 1px solid $border-color;
            }

            .place {
                width: 22%;
                padding: 5px;
                border-right: 1px solid $border-color;
                text-align: center;
            }

            ul {
                width: 70%;
                padding-left: 0;
                display: flex;
                margin-bottom: 0;
                margin-left: 0;
                align-items: center;

                li {
                    list-style-type: none;
                    padding: 0 10px;
                    width: calc(100% / 6);
                    text-align: center;
                }

            }
        }

        .clients {
            margin-bottom: 20px;
            background-color: rgb(246, 246, 246);
            
            .item {
                border: none !important;
                display: flex;

                h2 {
                    font-size: 1.1em;
                    width: 30%;
                    margin-bottom: 0;
                    padding-left: 20px;
                }

                ul {
                    display: flex;
                    flex-basis: 1;
                    width: 70%;
                    padding-left: 0;
                    margin-bottom: 0;

                    li {
                        list-style-type: none;
                        padding: 0 10px;
                        width: calc(100% / 6);
                        text-align: center;
                    }
                }

            }
        }

    }
}